/**
* @author zjc[beica1@outook.com]
* @date 2021/8/26 11:39
* @description
*   ShareLink.vue of WeTrade
*/
<template>
  <LinkPage>
    <div v-if="order" class="header px-24">
      <TheShareOrder :order="order" />
      <p class="t-right px-16 pb-16 c-white f-md">by {{name || '--'}}</p>
    </div>
  </LinkPage>
</template>

<script lang="ts">
import { Position } from '@/modules/trade/trade.api'
import TheShareOrder from '@/pages/invite/components/TheShareOrder.vue'
import { readPositionDetail } from '@/pages/invite/invite.api'
import LinkPage from '@/pages/invite/LinkPage.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'ShareLink',
  components: { TheShareOrder, LinkPage },
  setup () {
    const order = shallowRef<Position | null>(null)
    const route = useRoute()
    const orderId = route.query.order as string

    if (orderId) {
      readPositionDetail({
        orderId,
      }).then(resp => order.value = resp)
    }

    return {
      order,
      name: route.query.name,
    }
  },
})
</script>

<style scoped>
.header {
  background: var(--color-primary) url(/img/invite/blue@2x.png) bottom right no-repeat;
  background-size: 100%;
}
</style>
