
import Button from '@/components/Button.vue'
import { showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'
import { useInvite } from '@/pages/invite/invite'
import { readCCList, requestCode } from '@/pages/invite/invite.api'
import TheMobileInput from '@/pages/login/components/TheMobileInput.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import TheCodeInput from '@/pages/register/components/TheCodeInput.vue'
import { registerFormSchema } from '@/pages/register/register'
import { Data } from '@/types'
import Form from 'common/form/Form.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheForm',
  components: { ThePasswordInput, TheCodeInput, TheMobileInput, Button, Form },
  emits: ['register'],
  setup (props, ctx) {
    const { commit: _commit, progress } = useInvite()
    const route = useRoute()

    return {
      ccRequest: readCCList,
      commit (data: Data) {
        return _commit({
          ...route.query,
          ...data,
        }).then(() => {
          ctx.emit('register')
        })
      },
      progress,
      schema: registerFormSchema,
      sendCode (cc: string, mobile: string, cb: () => void) {
        requestCode({
          countryCode: cc,
          mobile,
          appId: route.query.appId,
          vestBagId: route.query.vestBagId,
        }).then(() => {
          showAlert(translate('toast_9', 'Code has been sent'))
          cb()
        })
      },
    }
  },
})
