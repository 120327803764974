/**
* @author zjc[beica1@outook.com]
* @date 2021/9/1 10:42
* @description
*   TheLand.vue of WeTrade
*/
<template>
  <div class="t-center f-lg f-bold">
    <Icon name="check" class="bg-success mb-20"
          style="font-size: 40px;color: white;border-radius: 20px" />
    <t as="p" path="sharelink_25"></t>
    <t as="p" path="sharelink_26"></t>
  </div>
  <div class="d-f mt-24">
    <div class="flex-1">
      <a href="https://apps.apple.com/us/app/wetrade-market-data-and-news/id1604139836">
        <Pic src="invite/apple_store" style="width: 100%" />
      </a>
    </div>
    <div class="flex-1 ml-24">
      <a href="https://play.google.com/store/apps/details?id=com.wtmob.app">
        <Pic src="invite/google_play" style="width: 100%" />
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheLand',
  components: { Pic, Icon },
})
</script>

<style scoped>

</style>
