/**
* @author zjc[beica1@outook.com]
* @date 2021/8/26 14:06
* @description
*   LinkPage.vue of WeTrade
*/
<template>
  <div class="spread">
    <div class="fill df-col">
      <Pic src="invite/link_header" style="width: 100%" />
      <Scroller css-only class="flex-1">
        <slot></slot>
        <div class="px-32 pt-24">
          <TheLand v-if="registered" />
          <template v-else>
            <div class="t-center f-bold f-lg">
              <t as="p" path="sharelink_9">Join me to trade with WeTrade</t>
            </div>
            <TheForm class="mt-16" @register="registered = true" />
          </template>
          <Line class="mt-32 mb-24" />
          <div class="df-middle mt-16">
            <Pic src="invite/money" width="48" height="48" class="mr-16" />
            <t as="p" path="sharelink_21"></t>
          </div>
          <div class="df-middle mt-16">
            <Pic src="invite/badge" width="48" height="48" class="mr-16" />
            <t as="p" path="sharelink_22"></t>
          </div>
          <div class="df-middle mt-16">
            <Pic src="invite/target" width="48" height="48" class="mr-16" />
            <t as="p" path="sharelink_23"></t>
          </div>
          <div class="df-middle mt-16">
            <Pic src="invite/cup" width="48" height="48" class="mr-16" />
            <t as="p" path="sharelink_24"></t>
          </div>
          <Pic src="invite/phone" style="width: 100%" class="mt-32" />
        </div>
      </Scroller>
    </div>
  </div>
</template>

<script lang="ts">
import Line from '@/components/Line.vue'
import Pic from '@/components/Pic.vue'
import TheForm from '@/pages/invite/components/TheForm.vue'
import TheLand from '@/pages/invite/components/TheLand.vue'
import Scroller from 'common/providers/Scroller.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'LinkPage',
  components: { TheLand, Scroller, Line, TheForm, Pic },
  setup () {
    const registered = shallowRef(false)

    return {
      registered,
    }
  },
})
</script>

<style scoped>

</style>
