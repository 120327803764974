/**
* @author zjc[beica1@outook.com]
* @date 2021/8/26 14:21
* @description
*   TheForm.vue of WeTrade
*/
<template>
  <Form :schema="schema" @submit="commit" #="{ values }">
    <TheMobileInput class="block px-8" :cc-request="ccRequest" />
    <TheCodeInput class="block px-8"
                  @send="cb => sendCode(values.countryCode, values.mobile, cb)" />
    <ThePasswordInput class="block px-8" />
    <Button class="mt-24 f-bold f-lg white primary block" :progress="progress">
      <t path="sharelink_20">Sign Up</t>
    </Button>
    <t #="{td, t}" custom>
      <p class="f-sm term mt-16 c-title">
        {{t('signup_9')}}
        <router-link to="/common/link?url=/terms_of_use.html&title=Terms"
                     class="c-primary f-medium">
          {{t('signup_10') || 'Term & Conditions'}}
        </router-link>
        {{t('signup_11')}}
        <router-link to="/common/link?url=/privacy_policy.html&title=Policy"
                     class="c-primary f-medium">
          {{t('signup_12') || 'Privacy Policy'}}
        </router-link>
      </p>
    </t>
  </Form>

</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import { showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'
import { useInvite } from '@/pages/invite/invite'
import { readCCList, requestCode } from '@/pages/invite/invite.api'
import TheMobileInput from '@/pages/login/components/TheMobileInput.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import TheCodeInput from '@/pages/register/components/TheCodeInput.vue'
import { registerFormSchema } from '@/pages/register/register'
import { Data } from '@/types'
import Form from 'common/form/Form.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheForm',
  components: { ThePasswordInput, TheCodeInput, TheMobileInput, Button, Form },
  emits: ['register'],
  setup (props, ctx) {
    const { commit: _commit, progress } = useInvite()
    const route = useRoute()

    return {
      ccRequest: readCCList,
      commit (data: Data) {
        return _commit({
          ...route.query,
          ...data,
        }).then(() => {
          ctx.emit('register')
        })
      },
      progress,
      schema: registerFormSchema,
      sendCode (cc: string, mobile: string, cb: () => void) {
        requestCode({
          countryCode: cc,
          mobile,
          appId: route.query.appId,
          vestBagId: route.query.vestBagId,
        }).then(() => {
          showAlert(translate('toast_9', 'Code has been sent'))
          cb()
        })
      },
    }
  },
})
</script>

<style scoped>

</style>
